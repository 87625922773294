import React, { useState } from 'react';
import {
  Box,
  SimpleGrid,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  IconButton,
  useDisclosure,
} from '@chakra-ui/react';
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import '../styles.css';

const Gallery = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const images = [
    '/appicon.png',
  ];

  const handleImageClick = (index) => {
    setSelectedImage(images[index]);
    setCurrentIndex(index);
    onOpen();
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
    setCurrentIndex(0);
    onClose();
  };

  const handleNextImage = () => {
    const newIndex = (currentIndex + 1) % images.length;
    setSelectedImage(images[newIndex]);
    setCurrentIndex(newIndex);
  };

  const handlePreviousImage = () => {
    const newIndex = (currentIndex - 1 + images.length) % images.length;
    setSelectedImage(images[newIndex]);
    setCurrentIndex(newIndex);
  };

  return (
    <Box
      minH="100vh"
      bgGradient="linear(to-b, #0d3b66, #3a86ff)" // Matching gradient
      color="white"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      p={4}
      pb={64}
      width="100%"
      overflowY="auto"
    >
      {/* Section Header */}
      <h1 className="page-title" style={{ marginBottom: '16px' }}>Gallery</h1>

      <Box flex="1" display="flex" flexDirection="column" justifyContent="center" alignItems="center" mb={12}>
        {/* Introductory Text */}
        <Box textAlign="center" mb={8} maxW="600px">
          <p>
            The gallery will feature the game’s music and artwork, which will be posted here gradually. Check back to explore the visual and auditory experience as it grows!
          </p>
        </Box>

        {/* Image Gallery with Only the App Icon */}
        <SimpleGrid columns={[1, null, 1]} spacing={10} mt={8} maxW="200px">
          {images.map((src, index) => (
            <Box
              key={index}
              p={2}
              _hover={{ transform: 'scale(1.05)' }}
              transition="transform 0.2s ease-in-out"
              onClick={() => handleImageClick(index)}
              cursor="pointer"
            >
              <Image src={src} alt={`Gallery image ${index + 1}`} objectFit="contain" />
            </Box>
          ))}
        </SimpleGrid>

        {/* Modal for Image Zoom */}
        <Modal isOpen={isOpen} onClose={handleCloseModal} isCentered>
          <ModalOverlay />
          <ModalContent
            bgGradient="linear(to-b, #0d3b66, #3a86ff)" // Matching modal background color
            color="white"
            maxW="80%"
            maxH="80vh"
            position="relative"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <ModalCloseButton
              position="absolute"
              top={2}
              right={2}
              backgroundColor="rgba(255, 255, 255, 0.7)"
              _hover={{ backgroundColor: 'white' }}
              color="black"
              borderRadius="full"
              zIndex="tooltip"
            />
            <ModalBody display="flex" justifyContent="center" alignItems="center" position="relative" p={4}>
              {selectedImage && (
                <Zoom>
                  <Image
                    src={selectedImage}
                    alt="Selected image"
                    maxW="100%"
                    maxH="60vh"
                    objectFit="contain"
                  />
                </Zoom>
              )}
              {/* Arrow Buttons */}
              <IconButton
                aria-label="Previous image"
                icon={<ArrowBackIcon />}
                position="absolute"
                left={2}
                top="50%"
                transform="translateY(-50%)"
                onClick={handlePreviousImage}
                backgroundColor="rgba(255, 255, 255, 0.7)"
                _hover={{ backgroundColor: 'white' }}
                color="black"
                borderRadius="full"
              />
              <IconButton
                aria-label="Next image"
                icon={<ArrowForwardIcon />}
                position="absolute"
                right={2}
                top="50%"
                transform="translateY(-50%)"
                onClick={handleNextImage}
                backgroundColor="rgba(255, 255, 255, 0.7)"
                _hover={{ backgroundColor: 'white' }}
                color="black"
                borderRadius="full"
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      </Box>
    </Box>
  );
};

export default Gallery;
