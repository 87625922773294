import React from 'react';
import { Box, Flex, Image } from '@chakra-ui/react';
import '../styles.css';

const About = () => {
  return (
    <Box
      minH="100vh"
      bgGradient="linear(to-b, #0d3b66, #3a86ff)"
      color="white"
      p={4}
      pb={24}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Flex
        direction="column"
        alignItems="center"
        width="100%"
        maxW="800px"
        textAlign="center"
      >
        {/* Game Summary */}
        <h1 className="page-title">Sub Zero: The Game</h1>
        <Box className="promo-text" maxW="600px" textAlign="center" mt={4}>
          <p>
            Set in the year 2147, Sub Zero: The Game plunges you into a future where Earth’s surface is uninhabitable. Humanity’s survival depends on deep-sea research bases scattered across the ocean floor. As a young boy, you explore Triton 631, the last hope for harnessing geothermal energy, but must navigate its dangers as disaster looms.
          </p>
        </Box>

        {/* Kibbo AI Companion */}
        <h2 className="section-header">Meet Your AI Companion: Kibbo</h2>
        <Box display="flex" justifyContent="center">
          <Image
            src="/images/Kibbo_Companion.png"
            alt="AI Kibbo"
            className="promo-image"
            my={8}
          />
        </Box>
        <Box className="promo-text" maxW="600px" textAlign="center" mt={4}>
          <p>
            Kibbo, your AI companion, is here to assist you through the depths of Triton 631. Trustworthy, knowledgeable, and occasionally mysterious, Kibbo guides you through the base's puzzles and provides crucial insights. But be cautious, as not everything Kibbo says may be as it seems.
          </p>
        </Box>

{/* World Map Blueprint */}
<h2 className="section-header">Explore Triton 631: The World Map Blueprint</h2>
<Box display="flex" justifyContent="center">
  <Image
    src="/images/World_Map.png"
    alt="Blueprint of Triton 631 World Map"
    className="promo-image"
    my={8}
    boxSize="80%" // Enlarges the image for better visibility
  />
</Box>
<Box className="promo-text" maxW="600px" textAlign="center" mt={4}>
  <p>
    Welcome to Triton 631, humanity's last refuge beneath the waves. The image above is a blueprint of the world map draft that players will explore in Sub Zero: The Game. As you journey through this sprawling deep-sea base, you'll encounter a series of interconnected rooms, each one filled with secrets, puzzles, and the remnants of a mission to secure Earth's future. From the quiet isolation of the Boy's Bedroom to the high-stakes environment of the Maintenance Room, every area tells a part of the story as you strive to prevent catastrophe and uncover the truth about Triton 631.
  </p>
</Box>
        {/* Game Publisher */}
        <h2 className="section-header">Game Publisher</h2>
        <Box className="promo-text" maxW="600px" textAlign="center" mt={4}>
          <p>
            The game is published by "Technically A Game," a Sweden-based game publisher. 
            Visit their website at <a href="https://technicallyagame.com/" target="_blank" rel="noopener noreferrer" style={{ color: 'black', textDecoration: 'underline', marginLeft: '4px' }}>Technically A Game</a> 
            to discover more games developed by them.
          </p>
        </Box>

        {/* Official App Icon */}
        <h2 className="section-header">Official App Icon</h2>
        <Box display="flex" justifyContent="center">
          <Image
            src="/appicon.png"
            alt="Sub Zero App Icon"
            className="promo-image-large"
            my={8}
            boxSize="100px"
          />
        </Box>
        <Box className="promo-text" maxW="600px" textAlign="center" mt={4}>
          <p>
            This is the official app icon for Sub Zero: The Game on the iOS App Store.
          </p>
        </Box>

        {/* Game Creator */}
        <h2 className="section-header">Game Creator</h2>
        <Box className="promo-text" maxW="600px" textAlign="center" mt={4}>
          <p>
            I am Peter Sandkvist, a web and app developer, game creator, and developer. 
            My passion is creating engaging experiences through interactive storytelling and challenging gameplay.
          </p>
        </Box>
        <Box display="flex" justifyContent="center">

        </Box>

        {/* Who Was Involved */}
        <h2 className="section-header">Who Was Involved?</h2>
        <Box display="flex" justifyContent="center">
        </Box>
        <Box className="promo-text" maxW="600px" textAlign="center" mt={4}>
          <p>
            The music for Sub Zero: The Game was composed by Joakim Bradman, who brought the world to life with his captivating soundscapes. 
            The stunning art and visuals were crafted by Andrea Hamdan, whose creativity and attention to detail made the game visually appealing and immersive.
          </p>
        </Box>

      </Flex>
    </Box>
  );
};

export default About;