import React from 'react';
import { Box, Flex, Link, Image } from '@chakra-ui/react';

const Footer = () => {
  return (
    <Box
      as="footer"
      bgGradient="linear(to-b, #0d3b66, #3a86ff)"
      position="fixed"
      bottom="0"
      left="0"
      width="100%"
      boxShadow="xl"
      py={{ base: 3, md: 6 }}
      px={{ base: 4, md: 8 }}
      borderTop="4px solid #1c3b5a" // Darker blue border
      overflow="hidden"
      color="white"
      fontFamily="Jersey10"
      zIndex={1}
      boxSizing="border-box"
    >
      <Flex justifyContent="center" alignItems="center" flexDirection="column">
        <p style={{ fontSize: '14px' }}>&copy; Sub Zero 2024</p>
        <Flex className="social-icons" mt={2}>
          <Link href="https://www.facebook.com/profile.php?id=61565884776142&mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer" mx={{ base: 1, md: 2 }}>
            <Image src="/images/facebook.png" alt="Facebook" className="social-icon" boxSize={{ base: '20px', md: '30px' }} />
          </Link>
          <Link href="https://x.com/zerodaythegame" target="_blank" rel="noopener noreferrer" mx={{ base: 1, md: 2 }}>
            <Image src="/images/x.png" alt="X (formerly Twitter)" className="social-icon" boxSize={{ base: '20px', md: '30px' }} />
          </Link>
          <Link href="https://instagram.com/zerodaythegame" target="_blank" rel="noopener noreferrer" mx={{ base: 1, md: 2 }}>
            <Image src="/images/instagram.png" alt="Instagram" className="social-icon" boxSize={{ base: '20px', md: '30px' }} />
          </Link>
        </Flex>
        <Link href="/privacy-policy" mt={3} color="white" _hover={{ color: 'gray.300' }} fontSize={{ base: '14px', md: '16px' }}>
          Privacy Policy
        </Link>
      </Flex>
    </Box>
  );
};

export default Footer;
