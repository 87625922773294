import React, { useState } from 'react';
import { Box, Button, Input, Textarea, Image } from '@chakra-ui/react';
import { FaPaperPlane } from 'react-icons/fa';
import '../styles.css';

const Support = () => {
  const [email, setEmail] = useState('');
  const [feedback, setFeedback] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email || !feedback) {
      alert('Please fill out both the email and feedback fields before submitting.');
      return;
    }

    try {
      const response = await fetch('https://zerodaythegame.com/submit-feedback', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, message: feedback }),
      });

      if (response.ok) {
        alert('Thank you for your feedback!');
        setEmail('');
        setFeedback('');
      } else {
        const errorData = await response.json();
        alert(`Error: ${errorData.error}`);
      }
    } catch (error) {
      alert('Failed to submit feedback. Please try again later.');
    }
  };

  return (
    <Box
      minH="100vh"
      bgGradient="linear(to-b, #0d3b66, #3a86ff)"
      color="white"
      p={4}
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <h1 className="page-title">Support</h1>

      {/* App Icon Image */}
      <Box display="flex" justifyContent="center" my={4}>
        <Image
          src="/appicon.png"
          alt="Sub Zero Day App Icon"
          className="promo-image-large"
        />
      </Box>

      <div
        className="qa-section"
        style={{ maxWidth: '300px', textAlign: 'center', fontSize: 'lg' }}
      >
        <p><strong>Q: When will the game be released?</strong><br />A: The game is planned for a 2024 Christmas release.</p>
        <p><strong>Q: Where can I find the privacy policy?</strong><br />A: You can view our privacy policy <a href="https://subzerothegame.com/privacy-policy" target="_blank" rel="noopener noreferrer" style={{ color: 'black', textDecoration: 'underline' }}>here</a>.</p>
        <p><strong>Q: How do I get support?</strong><br />A: Submit your feedback using the form below. We usually respond within 48 hours.</p>
      </div>

      <form onSubmit={handleSubmit} className="feedback-form" style={{ width: '80%', marginTop: '30px' }}>
        <Input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email"
          className="feedback-input"
          required
          mb={4}
          size="md"
          borderRadius="md"
          fontSize="sm"
          color="white"
          _placeholder={{ color: 'white' }}
          _focus={{ borderColor: 'black', boxShadow: 'none' }}
        />
        <Textarea
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
          placeholder="Please submit your feedback or questions to the developers here. We usually answer within 48 hours."
          rows="4"
          className="feedback-textarea"
          required
          mb={4}
          size="md"
          borderRadius="md"
          fontSize="sm"
          color="white"
          _placeholder={{ color: 'white' }}
          _focus={{ borderColor: 'black', boxShadow: 'none' }}
        />
        <Button
          type="submit"
          size="md"
          bgGradient="linear(to-r, orange.400, pink.500)"
          color="white"
          _hover={{ bgGradient: "linear(to-r, orange.500, pink.600)", transform: "scale(1.05)" }}
          boxShadow="xl"
          fontFamily="Jersey10"
          width="75%"
          mx="auto"
          leftIcon={<FaPaperPlane />}
          textShadow="2px 2px 4px rgba(0, 0, 0, 0.7)"
          mb={6}
        >
          Submit
        </Button>
      </form>

      {/* Add a large blank space below the submit button */}
      <Box height="100px" /> {/* Adjust the height as needed for the desired space */}
    </Box>
  );
};

export default Support;