import React, { useEffect } from 'react';
import { Box, Button } from '@chakra-ui/react';
import { FaDownload, FaTwitter, FaInstagram, FaFacebook } from 'react-icons/fa';
import '../styles.css';

const Home = () => {
  useEffect(() => {
    // Dynamically load the LightWidget script
    const script = document.createElement('script');
    script.src = 'https://cdn.lightwidget.com/widgets/lightwidget.js';
    script.defer = true;
    document.body.appendChild(script);
  }, []);

  return (
    <Box
      minH="100vh"
      bgGradient="linear(to-b, #0d3b66, #3a86ff)" // Matching gradient
      color="white"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      p={4}
      pb={24} // Add extra padding at the bottom
    >
      <h1 className="game-title">
        <span className="main-title">Sub Zero</span>
        <span className="sub-title"> Humanity's Last Hope</span>
      </h1>


      <Box className="promo-text" maxW="600px" textAlign="center" mt={3} mb={3}>
  <p>
  Set in 2147, <strong>Sub Zero</strong> takes place in a world where Earth is uninhabitable. Humanity’s last hope lies within Triton 631, a deep-sea research base. You play as a young boy who wakes up alone in the eerie corridors, piecing together the DORA 5 crew's final mission to harness geothermal energy. Unknowingly, he triggers a chain reaction that threatens not only his life but humanity’s future. Dive into a retro-futuristic world of puzzles and uncover secrets that could save – or doom – mankind.
  </p>
</Box>

{/* Move the PNG here */}
<img 
  src="/images/SubZero_Icon_1024.png" 
  alt="Game Promotion" 
  className="promo-image" 
  style={{ marginTop: '10px', marginBottom: '10px' }}
/>



      <Box height="100px" /> {/* Blank space at the bottom */}
    

<h2 className="section-header" style={{ marginTop: '20px', marginBottom: '20px' }}>Coming this Christmas! 🎄</h2>
<Box className="promo-text" maxW="600px" textAlign="center" mt={3} mb={3}>
  <p>
    The team is excited to announce that the sequel to <strong>Zero Day: The Game</strong> is currently in the works, with a planned release for Christmas 2024! We can't wait for you to dive into the deep-sea world of <strong>Sub Zero</strong> and uncover its mysteries. If you haven't already, we recommend playing the first game to fully experience the story and lore. Visit our official website for more details: <a href="https://zerodaythegame.com/" target="_blank" rel="noopener noreferrer" style={{ color: '#00BFFF', textDecoration: 'underline' }}>zerodaythegame.com</a> 🎮
  </p>
</Box>


<img 
  src="/images/Girl_Gamer.png" 
  alt="Game Promotion" 
  className="promo-image" 
  style={{ 
    marginTop: '10px', 
    marginBottom: '10px',
    width: '150px', // Set the width
    height: 'auto'  // Maintain aspect ratio
  }}
/>


      {/* Social Media Icons */}
      <Box mt={8} mb={8} textAlign="center">
        <h2 className="section-header">Follow Us on Social Media</h2>
        <Box mt={4}>
          <Button
            as="a"
            href="https://x.com/zerodaythegame"
            target="_blank"
            leftIcon={<FaTwitter />}
            m={2}
            colorScheme="twitter"
          >
            X (Twitter)
          </Button>
          <Button
            as="a"
            href="https://instagram.com/zerodaythegame"
            target="_blank"
            leftIcon={<FaInstagram />}
            m={2}
            colorScheme="pink"
          >
            Instagram
          </Button>
          <Button
            as="a"
            href="https://www.facebook.com/profile.php?id=61565884776142&mibextid=LQQJ4d"
            target="_blank"
            leftIcon={<FaFacebook />}
            m={2}
            colorScheme="facebook"
          >
            Facebook
          </Button>
        </Box>
      </Box>

{/* Instagram Feed via SnapWidget */}
<Box mt={0} mb={0} width="100%" maxW="765px">
  <h2 className="section-header">Latest on Instagram</h2>
  <Box mt={2}>
    <iframe
      src="https://snapwidget.com/embed/1079527"
      className="snapwidget-widget"
      allowTransparency="true"
      frameBorder="0"
      scrolling="no"
      style={{ 
        border: 'none', 
        overflow: 'hidden', 
        width: '100%', 
        height: '600px', 
        margin: '0px !important', 
        padding: '0px !important', 
        display: 'block' 
      }}
      title="Posts from Instagram"
    ></iframe>
  </Box>
</Box>

      <Box height="50px" /> {/* Blank space at the bottom */}
    </Box>
  );
};

export default Home;