import React from 'react';
import { Box, Image, Spinner } from '@chakra-ui/react';

const LoadingScreen = () => {
  return (
    <Box
      position="fixed"
      top="0"
      left="0"
      width="100vw"
      height="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      bgGradient="linear(to-b, #0d3b66, #3a86ff, gray.900)" // Updated gradient colors with gray at the end
      color="white"
      zIndex="9999"
    >
      {/* Centered Logo Image */}
      <Image
        src="/images/icon_transparent.png" // Updated to use icon_transparent.png from images folder
        alt="Loading Logo"
        width="50%"
        maxWidth="200px"
        height="auto"
        mb={8}
      />

      {/* Spinner with Black Border */}
      <Box
        border="2px solid black"
        borderRadius="full"
        p="2"
        display="inline-flex"
      >
        <Spinner
          size="xl"
          thickness="14px"
          speed="1.2s"
          color="black"
        />
      </Box>
    </Box>
  );
};

export default LoadingScreen;
