import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import '../styles.css'; // Ensure the styles are imported

const PrivacyPolicy = () => {
  return (
    <Box
      minH="100vh" // Ensures the content covers the full height of the viewport
      width="100vw" // Ensures the content spans the full width of the viewport
      bgGradient="linear(to-b, #0d3b66, #3a86ff)"
      color="white"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      p={4}
      pb={24} // Add extra padding at the bottom
    >
      <Flex
        direction="column"
        alignItems="center"
        width="100%"
        maxW="800px"
        textAlign="center"
      >
        <h1 className="section-header">Privacy Policy</h1>
        <Box className="promo-text" maxW="600px" textAlign="center" mt={4}>
          <p>Effective Date: 2024-10-12</p>
          <p>
            We are committed to protecting your privacy. This Privacy Policy explains how we handle any data associated with the use of our iOS game, Sub Zero: The Game.
          </p>
        </Box>

        <h2 className="section-header" style={{ marginTop: '30px' }}>No Data Collection</h2>
        <Box className="promo-text" maxW="600px" textAlign="center" mt={4}>
          <p>
            We do not collect, store, or share any personal data or information from users of our app. Sub Zero: The Game is designed to be played without the need to provide any personal information, create an account, or share any data with us.
          </p>
        </Box>

        <h2 className="section-header" style={{ marginTop: '30px' }}>Third-Party Services</h2>
        <Box className="promo-text" maxW="600px" textAlign="center" mt={4}>
          <p>
            Our game does not integrate with any third-party services, analytics tools, or advertising networks that may collect or share user data.
          </p>
        </Box>

        <h2 className="section-header" style={{ marginTop: '30px' }}>Children's Privacy</h2>
        <Box className="promo-text" maxW="600px" textAlign="center" mt={4}>
          <p>
            Our game is intended for a general audience and does not target children under the age of 13. Since we do not collect any personal data, there is no risk of inadvertently collecting information from children.
          </p>
        </Box>

        <h2 className="section-header" style={{ marginTop: '30px' }}>Changes to This Privacy Policy</h2>
        <Box className="promo-text" maxW="600px" textAlign="center" mt={4}>
          <p>
            We may update this Privacy Policy from time to time. Any changes will be posted on this page, and the "Effective Date" at the top of this policy will be updated accordingly. We encourage you to review this policy periodically to stay informed about how we protect your privacy.
          </p>
        </Box>

        <h2 className="section-header" style={{ marginTop: '30px' }}>Contact Us</h2>
        <Box className="promo-text" maxW="600px" textAlign="center" mt={4}>
          <p>
            If you have any questions or concerns about this Privacy Policy or our practices, please feel free to contact us using the contact form on the Support page.
          </p>
        </Box>
      </Flex>
    </Box>
  );
};

export default PrivacyPolicy;