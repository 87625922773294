import React from 'react';
import { Box, Flex, Link } from '@chakra-ui/react';
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

const Header = ({ activeSection }) => {
  const particlesInit = async (main) => {
    await loadFull(main);
  };

  return (
    <Box
      as="header"
      position="relative"
      backgroundImage="url('/images/Header.png')"
      backgroundPosition="center"
      backgroundSize="cover"
      backgroundRepeat="no-repeat"
      boxShadow="xl"
      py={{ base: 2, md: 4 }}
      px={{ base: 4, md: 8 }}
      width="100%"
      top={0}
      zIndex={10}
      borderBottom="4px solid #1c3b5a"
      overflow="hidden"
      _before={{
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(13, 59, 102, 0.6)',
        zIndex: 1,
      }}
    >
      {/* Bubble particles */}
      <Particles
        init={particlesInit}
        options={{
          fullScreen: { enable: false },
          particles: {
            number: {
              value: 80, // Increase for more bubbles
              density: { enable: true, area: 800 },
            },
            color: { value: "#ffffff" },
            opacity: {
              value: 0.8,
              random: true,
              anim: { enable: false }, // Static opacity
            },
            size: {
              value: { min: 8, max: 15 }, // Random sizes for variety
              random: true,
              anim: { enable: false }, // Static size
            },
            move: {
              direction: "top",
              outModes: { top: "out", default: "none" }, // Disappear at the top
              speed: { min: 1, max: 2 }, // Vary speed for a natural flow
              straight: true,
              enable: true,
            },
            shape: { type: "circle" },
          },
          detectRetina: true, // Clearer display on retina screens
        }}
        style={{
          position: "absolute",
          bottom: 0, // Start particles from the bottom
          width: "100%",
          height: "100%",
          zIndex: 0,
          pointerEvents: "none", // Non-interactive
        }}
      />

      <Flex justifyContent="center" zIndex={2} position="relative">
        <Link
          href="#"
          mx={{ base: 2, md: 4 }}
          color="white"
          fontWeight="bold"
          fontSize={{ base: '20px', md: '28px' }}
          fontFamily="Jersey10"
          _hover={{ color: 'gray.300' }}
          borderBottom={activeSection === 0 ? '2px solid white' : 'none'}
          onClick={() => window.fullpage_api.moveTo(1)}
        >
          Home
        </Link>
        <Link
          href="#"
          mx={{ base: 2, md: 4 }}
          color="white"
          fontWeight="bold"
          fontSize={{ base: '18px', md: '28px' }}
          fontFamily="Jersey10"
          _hover={{ color: 'gray.300' }}
          borderBottom={activeSection === 1 ? '2px solid white' : 'none'}
          onClick={() => window.fullpage_api.moveTo(2)}
        >
          About
        </Link>
        <Link
          href="#"
          mx={{ base: 2, md: 4 }}
          color="white"
          fontWeight="bold"
          fontSize={{ base: '18px', md: '28px' }}
          fontFamily="Jersey10"
          _hover={{ color: 'gray.300' }}
          borderBottom={activeSection === 2 ? '2px solid white' : 'none'}
          onClick={() => window.fullpage_api.moveTo(3)}
        >
          Support
        </Link>
        <Link
          href="#"
          mx={{ base: 2, md: 4 }}
          color="white"
          fontWeight="bold"
          fontSize={{ base: '18px', md: '28px' }}
          fontFamily="Jersey10"
          _hover={{ color: 'gray.300' }}
          borderBottom={activeSection === 3 ? '2px solid white' : 'none'}
          onClick={() => window.fullpage_api.moveTo(4)}
        >
          Gallery
        </Link>
      </Flex>
    </Box>
  );
};

export default Header;
